import React from 'react';
import {graphql, Link, useStaticQuery} from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../../../../components/Layout';
import SEO from '../../../../components/seo';
import Content from '../../../../components/Content';
import Block from '../../../../components/Block';
import Hero from '../../../../components/Hero';
import {
	HighlightedParagraph,
	HighlightedTitle,
} from '../../../../components/highlightedText';
import Anchors from '../../../../components/Anchors';
import Subnavigation from '../../../../components/Subnavigation';
import PersonBadge from '../../../../components/PersonBadge';
import ContentImage from '../../../../components/ContentImage';
import Breadcrumb from '../../../../components/Breadcrumb';
import {Button, Icon} from '@gisatcz/ptr-atoms';
import {getSectorSubpageLinkByKeys} from '../../../../selectors/data';
import {lang} from '../../../../helpers/lang';

const Geohazards = ({pageContext}) => {
	const data = useStaticQuery(graphql`
		{
			floodImage: file(
				relativePath: {eq: "pages/work/sectors/geohazards/flood.png"}
			) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
			background: file(relativePath: {eq: "pages/work/sectors/fire.jpg"}) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
		}
	`);

	return (
		<Layout theme={pageContext.sector.theme}>
			<SEO title={pageContext.sector.title} lang={lang} />
			<Hero
				backgroundImageFluid={data.background.childImageSharp.gatsbyImageData}
			>
				<Breadcrumb crumbs={pageContext?.crumbs} />
				<HighlightedTitle>Geohazards mapping and monitoring</HighlightedTitle>
				<HighlightedParagraph>
					Risk assessment require up-to-date information about hazard status and
					insight into its evolution in space and time.
				</HighlightedParagraph>
			</Hero>
			<Block light>
				<Content>
					<Subnavigation>
						<Anchors>
							<a href={'#ground-motions'}>Ground motions</a>
							<a href={'#landslides'}>Landslides</a>
							<a href={'#flood-mapping'}>Flood mapping and monitoring</a>
							<a href={'#drought-monitoring'}>Drought monitoring</a>
							<a href={'#burnt-area-detection'}>Burnt area detection</a>
						</Anchors>
						<PersonBadge
							note={pageContext.sector.contactNote}
							email={pageContext.sector.contactPerson}
						/>
					</Subnavigation>
				</Content>
			</Block>
			<Block light>
				<Content>
					<p>
						Human and material losses caused by disasters are a major obstacle
						to sustainable development. Hazards occur across different time and
						area scales and each is in some way unique, but their frequency is
						expected to even increase in the future related to extreme weather
						events due to climate change.
					</p>
					<p>
						Earth observation supports regular monitoring of hazards prone
						areas, accurate forecasts and warnings as well as people's awareness
						and overall preparedness against such hazards, before they become
						disasters. We provide solutions to support such regular monitoring,
						forecasting and assessment of hazards and associated risks. Thus
						resilience of particular areas can be increased, mitigation planes
						prepared and disaster impacts reduced with lives and properties
						effectively protected.
					</p>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'ground-motions'}>Ground motions</h2>
					<p>
						Multitemporal satellite interferometry allows the detection of
						subtle ground displacements with millimetric precision. Our scalable
						solution provides insight into spatial patterns and intensity of
						ground motions related to various driving phenomena: ground
						subsidence, active slope instabilities, displacements and
						deformations of buildings and infrastructure assets
					</p>
					<p>
						Nowadays, commercial and non-commercial satellite-borne radar
						sensors provide a range of spatial resolutions, wavelengths,
						acquisition directions and measurement frequencies. Retrospective
						mapping based on archived InSAR images shows historical or current
						displacements. Monitoring using measurements on a continual basis
						with high frequency reveals emerging threats indicated by changes in
						deformation trend. We help our clients to choose a sensor and
						tailored interferometric solution to provide the best performance
						and cost-benefit for their application, domain, and deformation
						phenomena characteristics.
					</p>
					{/*<ContentImage fluid={null} legend={<>Visualizations</>} />*/}
					{/*<Link*/}
					{/*	to={getSectorSubpageLinkByKeys(*/}
					{/*		pageContext.sector.key,*/}
					{/*		'groundMotions'*/}
					{/*	)}*/}
					{/*	className="with-icon"*/}
					{/*>*/}
					{/*	Learn more about Ground motions*/}
					{/*	<Icon className="gst-link-icon-right" icon="back" />*/}
					{/*</Link>*/}
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'landslides'}>Landslides</h2>
					<p>
						We strive to address landslide hazard mapping by a holistic approach
						by utilising a scalable mix of satellite data and earth observation
						techniques. Satellites are used to identify historical or recent
						landslide sites. SAR data and the InSAR technique facilitate the
						assessment of slope stability by detecting slow slope motions by
						retrospective mapping or monitoring in proactive mode.
					</p>
					{/*<ContentImage fluid={null} legend={<>Visualizations</>} />*/}
					{/*<Link*/}
					{/*	to={getSectorSubpageLinkByKeys(*/}
					{/*		pageContext.sector.key,*/}
					{/*		'landslides'*/}
					{/*	)}*/}
					{/*	className="with-icon"*/}
					{/*>*/}
					{/*	Learn more about Landslides*/}
					{/*	<Icon className="gst-link-icon-right" icon="back" />*/}
					{/*</Link>*/}
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'flood-mapping'}>Flood mapping and monitoring</h2>
					<p>
						Flood management and design of flood protection & mitigation actions
						require geospatial intelligence applicable from city to regional
						scale. Our solution supports the clients with flood inundation maps
						to identify the extent, intensity and also evolution of the hazard
						and its impacts. Flood water extent is extracted from optical or SAR
						imagery acquired during the flood event. Analysis of time series of
						archived satellite images coinciding with past flooding events
						allows us to assess flooding evolution, dynamics, frequency or
						flooding peak intervals. In addition, flooding depth is derived from
						a digital terrain model or by hydraulic modelling.
					</p>
					<p>
						Associated hazard levels represent input into flood risk models.
						Together with vulnerability and resilience, the hazard component is
						substituted into a risk formula to estimate relevant impacts for
						land use classes, population or infrastructure assets.
					</p>
					{/*<ContentImage fluid={null} legend={<>Visualizations</>} />*/}
					{/*<Link*/}
					{/*	to={getSectorSubpageLinkByKeys(*/}
					{/*		pageContext.sector.key,*/}
					{/*		'floodMapping'*/}
					{/*	)}*/}
					{/*	className="with-icon"*/}
					{/*>*/}
					{/*	Learn more about Flood mapping and monitoring*/}
					{/*	<Icon className="gst-link-icon-right" icon="back" />*/}
					{/*</Link>*/}
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'drought-monitoring'}>Drought monitoring</h2>
					<p>
						Drought that can be understood as a prolonged dry period caused by a
						lack of rainfall has a direct negative impact on the environment.
						Compounding factors, such as inappropriate land use, poverty and
						altered weather patterns due to climate change increase the
						vulnerability to drought. Drought affects water sources, soil, land
						as well as health of wildlife, plant and man communities. The
						decrease in available water can lead to a reduction of wetlands,
						loss of biodiversity, groundwater depletion and even impact water
						quality.
					</p>
					<p>
						EO based vegetation canopy indicators enable regular monitoring of
						drought risk and the assessment of its spatial-temporal distribution
						and severity. Also, tailored services can be provided for monitoring
						damages to crop production caused by the drought or other
						meteorological events.
					</p>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'burnt-area-detection'}>Burnt area detection</h2>
					<p>
						Forest fire, both natural or caused by human carelessness, presents
						a destructive event with a severe impact on the vegetation cover and
						soil. Change detection between pre-event and post-event satellite
						imagery allows fast and reliable detection of the burnt area extent.
						A relative damage grading is derived using specialized vegetation
						indexes to better quantify the vegetation loss caused by the fire
						event, and supplementary Land Cover data are used to assess
						environmental impact. Vegetation regeneration processes can be
						assessed from a time series of post-event satellite data.
					</p>
					{/*<ContentImage fluid={null} legend={<>Visualizations</>} />*/}
					{/*<Link*/}
					{/*	to={getSectorSubpageLinkByKeys(*/}
					{/*		pageContext.sector.key,*/}
					{/*		'burntAreaDetection'*/}
					{/*	)}*/}
					{/*	className="with-icon"*/}
					{/*>*/}
					{/*	Learn more about Burnt area detection*/}
					{/*	<Icon className="gst-link-icon-right" icon="back" />*/}
					{/*</Link>*/}
				</Content>
			</Block>
		</Layout>
	);
};

Geohazards.propTypes = {
	pageContext: PropTypes.object.isRequired,
};

export default Geohazards;
